// Load our external components
const ExternalLinks = require('./external-links')
const Navigation = require('./navigation')
const Showcase = require('./showcase')
// const Backdrop = require('./backdrop')
const Video = require('./video')
const Logo = require('./logo')
const Page = require('./page-transition')

// Inject our page components
Page.addComponents([
  ExternalLinks,
  Navigation,
  Showcase,
  // Backdrop,
  Video,
  Logo
])

// Start!
Page.init()
