let videoElements = '.showcase video'
let cleanup = []

function disableVideoElements(container) {
  container.querySelectorAll(videoElements).forEach(function(vid) {
    // Replace video element with poster image
    let poster = document.createElement('img')
    let parent = vid.parentNode

    poster.src = vid.getAttribute('poster')
    poster.onload = function(e) {
      parent.insertBefore(poster, vid)
      vid.remove()
    }
  })
}

function enableVideoElements(container) {
  container.querySelectorAll(videoElements).forEach(function(vid) {
    vid.preload = 'auto'
    vid.autoplay = true
    vid.play()
  })
}

// Exposed methods
module.exports = {
  register: function(container) {
    if (!window.matchMedia('(min-width: 767px)').matches) {
      // On mobile we don't want to show the showcase video, instead show
      // the poster as an image element.
      disableVideoElements(container)
    } else {
      enableVideoElements(container)
    }
  },

  unregister: function(container) {
    for (let fn of cleanup) fn()
  }
}
