// Exposed methods
module.exports = {
  register: function(container) {
    // NOTE: Firefox does not support (hover: hover)
    let isDesktopFirefox =
      navigator.userAgent.match(/firefox/i) &&
      window.matchMedia('(min-width: 768px)').matches

    if (
      !isDesktopFirefox &&
      !window.matchMedia('(min-width: 768px) and (hover: hover)').matches
    ) {
      return
    }

    // Add the alternating logo events, the 1s delay is in the css animation
    container.querySelectorAll('.logo').forEach(function(logo) {
      logo.addEventListener('mouseenter', function(e) {
        logo.classList.add('alternating')
      })

      logo.addEventListener('mouseleave', function(e) {
        logo.classList.remove('alternating')
      })
    })
  },

  unregister: function(container) {}
}
