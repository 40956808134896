// A tiny page cache object
let Cache = {
  pages: {},

  // Preload this path
  preload: function(path) {
    if (!Cache.has(path)) {
      Cache.add(
        path,
        fetch(path).then(function(response) {
          return response.text()
        })
      )
    }

    return Cache.get(path)
  },

  // Add promise to catch
  add: function(path, promise) {
    Cache.pages[path] = promise
    return promise
  },

  // Is this path cached?
  has: function(path) {
    return path in Cache.pages
  },

  // Get the promise from cache
  get: function(path) {
    return Cache.pages[path]
  }
}

// Page object handling events and transitions
let Page = {
  components: [],
  timers: [],
  BACKWARDS: 'backwards',
  FORWARDS: 'forwards',
  isLoading: false,

  initialize: function() {
    // Add current page to history stack
    history.replaceState({ awk: true }, document.title, location.href)

    // Listen to history changes
    window.addEventListener('popstate', function(e) {
      if (!e.state.awk) return
      Page.goto(location.pathname, false, Page.FORWARDS, true)
    })

    // Add the current path/document to the cache
    const initialDocument = document.documentElement.outerHTML
    Cache.add(
      location.pathname,
      new Promise(function(resolve) {
        resolve(initialDocument)
      })
    )

    // Bind page events
    Page.bindNavigationEvents(document.body)
    Page.triggerComponents('register', document.body)
  },

  registerComponents: function(components) {
    Page.components = components
  },

  triggerComponents: function(type, container) {
    type = type === 'register' ? 'register' : 'unregister'
    for (let component of Page.components) {
      Page.delayedEvent(function() {
        component[type](container)
      }, 500)
    }
  },

  bindNavigationEvents: function(container) {
    document.querySelectorAll('a:not([rel="external"])').forEach(function(a) {
      // Preload on mouseover (this works only with pointer input of course)
      a.addEventListener('mouseover', function(e) {
        Cache.preload(this.pathname)
      })

      // On click go visit the page
      a.addEventListener('click', function(e) {
        // Ignore if any modifier key is used with the click
        if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) return

        // Ignore for e-mail links
        if (this.href.match(/^mailto/)) return

        e.preventDefault()

        // Ignore active links
        if (e.target.getAttribute('class') === 'active') return

        Page.goto(this.pathname, true)
        return false
      })
    })
  },

  delayedEvent: function(fn, timeout) {
    Page.timers.push(setTimeout(fn, timeout))
  },

  clearDelayedEvents: function() {
    for (let timer of Page.timers) {
      clearTimeout(timer)
    }

    Page.timers = []
  },

  goto: function(path, pushToHistory) {
    if (!Cache.has(path)) return
    if (Page.isLoading === path) return

    // Remember what we are loading
    Page.isLoading = path

    // Cleanup any pending/finished delayed events
    Page.clearDelayedEvents()

    // Define some useful variables
    let currentPage = document.querySelector('.page')

    Cache.preload(path)
      .then(function(contents) {
        // Get the new title and page contents
        let tmp = document.createElement('div')
        tmp.innerHTML = contents
        let newTitle = tmp.querySelector('title')
          ? tmp.querySelector('title').innerText
          : false
        let newPage = tmp.querySelector('.page')

        if (document.querySelector('.menu-control:checked')) {
          // mobile view skips animation
          document.querySelector('.menu-control:checked').checked = false
          window.scrollTo(0, 0)
          currentPage.remove()
          document.body.insertBefore(newPage, currentPage.nextSibling)
        } else {
          currentPage.querySelector('.content').classList.add('leave')
          if (currentPage.querySelector('.footer')) {
            currentPage.querySelector('.footer').classList.add('pre-leave')
          }

          // ALL INSTANT EVENTS //
          currentPage.querySelectorAll('.menu a').forEach(function(el) {
            if (el.pathname === path) {
              el.classList.add('active')
            } else {
              el.classList.remove('active')
            }
          })

          // Set the new title, if applicable
          if (newTitle) {
            document.title = newTitle
          }

          // Add to the history stack, if applicable
          if (pushToHistory) {
            history.pushState({ awk: true }, newTitle, path)
          }

          // Set initial state for new contnet
          newPage.querySelector('.content').classList.add('pre-appear')
          if (newPage.querySelector('div.showcase')) {
            newPage
              .querySelector('div.showcase')
              .classList.add('pre-appear-element')
          }
          if (newPage.querySelector('.footer')) {
            newPage.querySelector('.footer').classList.add('pre-appear-element')
          }
          // ALL INSTANT EVENTS //

          Page.delayedEvent(function() {
            // Insert the new page into the dom (and cleanup tmp)
            currentPage.remove()
            document.body.insertBefore(newPage, currentPage.nextSibling)
            tmp = null

            // Register all component events for the new page, and remove the old ones
            Page.triggerComponents('unregister', currentPage)
            Page.triggerComponents('register', newPage)
            Page.bindNavigationEvents(newPage)

            Page.delayedEvent(
              function() {
                window.scrollTo(0, 0)
                if (newPage.querySelector('div.showcase')) {
                  newPage
                    .querySelector('div.showcase')
                    .classList.remove('pre-appear-element')
                }

                newPage.querySelector('.content').classList.remove('pre-appear')

                Page.delayedEvent(function() {
                  if (newPage.querySelector('.footer')) {
                    newPage
                      .querySelector('.footer')
                      .classList.remove('pre-appear-element')
                  }
                }, 400)
              },
              10 // this is needed otherwise the animation is borked of the new page
            )
            Page.isLoading = false
          }, 400)
        }
      })
      .catch(err => {
        Page.isLoading = false
      })
  }
}

// Exposed methods
module.exports = {
  init: function() {
    Page.initialize()
  },

  addComponents: function(components) {
    Page.registerComponents(components)
  }
}
