let videoElements = '.figure video, .backdrop video, .screenshots video'
let mutableVideoElements = '.figure video'
let cleanup = []

function enableNativeControls(container) {
  container.querySelectorAll(videoElements).forEach(function(vid) {
    vid.controls = true
  })
}

function enableVideoElements(container) {
  // First we add mute buttons where needed
  container.querySelectorAll(mutableVideoElements).forEach(function(vid) {
    let parent = vid.parentNode

    // Create the mute button
    let muteBtn = document.createElement('button')
    muteBtn.classList.add('mute-button')
    muteBtn.addEventListener('click', function(e) {
      vid.muted = !vid.muted
      parent.classList.toggle('muted')
      e.preventDefault()
      return false
    })

    // Append the mute button
    parent.appendChild(muteBtn)
    parent.classList.add('muted')
  })

  // Then we attach our video events
  container
    .querySelectorAll('.figure video, .backdrop video, .screenshots video')
    .forEach(function(vid) {
      // Add play/pause on click to video
      vid.addEventListener('click', function(e) {
        if (vid.paused) vid.play()
        else vid.pause()
      })

      // Add scroll listener
      let listener = function(e) {
        if (!vid.paused) return
        if (
          vid.getBoundingClientRect().top -
            vid.getBoundingClientRect().height / 3 <=
          0
        ) {
          vid.play()
        }
      }

      // Add the scroll listener (and remember to cleanup)
      window.addEventListener('scroll', listener, false)
      cleanup.push(function() {
        window.removeEventListener('scroll', listener, false)
      })
    })
}

// Exposed methods
module.exports = {
  register: function(container) {
    if (!window.matchMedia('(min-width: 767px)').matches) {
      enableNativeControls(container)
    } else {
      enableVideoElements(container)
    }
  },

  unregister: function(container) {
    for (let fn of cleanup) fn()
  }
}
