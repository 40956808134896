let firstHighlighted = false
let hasExpanded = false
let menuHover = null
let cleanup = []

function setFocusOn(container, navItem) {
  container.querySelectorAll('.navigation li').forEach(function(item) {
    if (item.classList.contains('visible')) return
    if (navItem === item) item.classList.remove('faded')
    else item.classList.add('faded')
  })
}

function removeFocus(container) {
  container.querySelectorAll('.navigation li').forEach(function(item) {
    item.classList.remove('faded')
  })
}

function enableHoverPreviews(container) {
  // Apply the preview hover effect to all links
  container.querySelectorAll('.navigation a').forEach(function(item) {
    let menuTimer = -1

    // Does this link have a preview?
    let previewContents = item.querySelector('.preview')
    if (!previewContents) return

    // Hide the preview and append to the hover element
    previewContents.style.opacity = 0
    let clonePreview = previewContents.cloneNode(true)
    menuHover.appendChild(clonePreview)

    // Autoload and autoplay videos
    let vid = clonePreview.querySelector('video')
    if (vid) {
      vid.preload = 'auto'
      vid.autoplay = true
    }

    // Automatically "hover" over the first item (with preview)
    if (!firstHighlighted) {
      firstHighlighted = true
      setFocusOn(container, item.parentNode)
      menuHover.style.display = 'block'
      let video = clonePreview.querySelector('video')
      if (video) video.play()
      setTimeout(function() {
        clonePreview.style.opacity = 1
      }, 50)

      // Hide preview when footer is there
      function scrollEvent() {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight -
            document.querySelector('footer').offsetHeight
        ) {
          clonePreview.style.opacity = 0
          if (video) video.pause()
          removeFocus(container)
          window.removeEventListener('scroll', scrollEvent)
        }
      }
      window.addEventListener('scroll', scrollEvent)
    }

    // Show preview on mouseenter
    item.addEventListener('mouseenter', function(e) {
      clearTimeout(menuTimer)

      // Fade out all other element
      setFocusOn(container, item.parentNode)

      // Show the menu hover
      menuHover.style.display = 'block'

      // Find the current preview element needed
      menuHover.querySelectorAll('.preview').forEach(function(el) {
        let video = el.querySelector('video')

        // If the element matches, make it visible and play video (if applicable)
        if (el === clonePreview) {
          el.style.opacity = 1
          if (video) video.play()
        } else {
          // By default hide and pause all other preview elements
          el.style.opacity = 0
          if (video) video.pause()
        }
      })
    })

    // Hide preview on mouseleave
    item.addEventListener('mouseleave', function(e) {
      clearTimeout(menuTimer)

      // Fade back all other element
      removeFocus(container)

      // Delayed hide the menuHover
      menuTimer = setTimeout(function() {
        if (!clonePreview) return

        // Hide both the preview
        clonePreview.style.opacity = 0

        // If there is a video pause it
        let video = clonePreview.querySelector('video')
        if (video) video.pause()
      }, 50)
    })
  })
}

function enableExpandTrigger(container) {
  // Enable the "More \/" dropdown to work
  container.querySelectorAll('li.collapsed').forEach(function(menu) {
    // Should it already be expanded?
    if (hasExpanded) {
      menu.querySelector('span').remove()
      menu.classList.remove('collapsed')
      menu.classList.add('visible')
      return
    }

    // On click, expand the list
    const expand = function(e) {
      e.preventDefault()
      menu.removeEventListener('mouseenter', menuFocus, false)
      menu.removeEventListener('mouseleave', menuBlur, false)
      menu.removeEventListener('click', expand, false)
      menu.querySelector('span').remove()
      menu.classList.remove('collapsed')

      // Remember that More has been clicked during this session
      hasExpanded = true

      // Trigger the animation to make items visible
      setTimeout(function() {
        menu.classList.add('visible')
      }, 10)
    }

    const menuFocus = function(e) {
      setFocusOn(container, menu)
    }

    const menuBlur = function(e) {
      removeFocus(container)
    }

    // Add the event listeners
    menu.addEventListener('mouseenter', menuFocus, false)
    menu.addEventListener('mouseleave', menuBlur, false)
    menu.addEventListener('click', expand, false)
  })
}

// Exposed methods
module.exports = {
  register: function(container) {
    // Create the hover preview element
    menuHover = document.createElement('div')
    menuHover.className = 'navigation-preview'

    if (document.getElementById('navigation')) {
      document.getElementById('navigation').parentNode.appendChild(menuHover)
    }

    enableExpandTrigger(container)

    if (window.matchMedia('(min-width: 768px) and (hover: hover)').matches) {
      enableHoverPreviews(container)
    } else if (
      navigator.userAgent.match(/firefox/i) &&
      window.matchMedia('(min-width: 768px)').matches
    ) {
      // NOTE: Firefox does not (yet) support (hover: hover)
      enableHoverPreviews(container)
    } else {
      // Remove preview elements
      menuHover.querySelectorAll('.preview').forEach(function(el) {
        el.remove()
      })
    }
  },

  unregister: function(container) {
    if (menuHover) {
      menuHover.remove()
      menuHover = null
    }

    for (let fn of cleanup) fn()
  }
}
