// Exposed methods
module.exports = {
  register: function(container) {
    // All external links should open in a new window
    container.querySelectorAll('a[rel=external]').forEach(function(a) {
      a.addEventListener('click', function(e) {
        // Ignore if any modifier key is used with the click
        if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) return

        // Ignore for e-mail links
        if (this.href.match(/^mailto/)) return

        // Ignore for tel links
        if (this.href.match(/^tel/)) return

        e.preventDefault()
        window.open(this.href)
      })
    })
  },

  unregister: function(container) {}
}
